@mixin sorting($icon, $icon-color){
  background: none;
  position: relative;

  &:after{
    content: $icon;
    color: $icon-color;
    font-family: FontAwesome;
    position: absolute;
    right: 2px;
  }
}

.dataTables_wrapper{
  label{
    font-weight: normal;
    font-size: 14px;
  }

  .dataTables_filter{
    display: none;
    margin-bottom: 10px;

    input[type="search"]{
      border-radius: 4px;
      border: 1px solid #adbbc2;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }
  }

  table.dataTable thead{
    .sorting{
      @include sorting('\f0dc', $white);
    }

    .sorting_asc{
      @include sorting('\f0de', $brand-primary);
    }

    .sorting_desc{
      @include sorting('\f0dd', $brand-primary);
    }
  }

  .dataTables_info{
    font-size: 12px;
  }

  table.dataTable.no-footer{
    border: 1px solid #ccc;
  }

  .dataTables_paginate{
    font-size: 12px;
    margin-top: 5px;

    .paginate_button{
      background: $white;
      border-color: #ccc;
      padding: 3px 8px;

      &:hover{
        background: $brand-secondary;
        border-color: #bbb;
        color: $text-color !important;
      }

      &:active{
        box-shadow: none;
      }

      &.current{
        background: $brand-primary;
        border-color: darken($brand-primary, 5%);
        color: $white !important;

        &:hover{
          background: darken($brand-primary, 8%);
          border-color: darken($brand-primary, 5%);
          color: $white !important;
        }
      }
    }
  }
}

#notes_wrapper {
  #notes_filter {
    display: block !important;
  }
}

.text-limit {
  max-width: 475px;
  overflow: hidden;
  text-overflow: ellipsis;
}