// Colors
$white: #fff;
$light-gray: #edeeef;
$dark-gray: #46555d;
$black: #000;

// Body
$body-bg: $white;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #f4751c;
$brand-secondary: #edeeef;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;
$notifications: #e2490e;

// Typography
$font-family-sans-serif: "Open Sans", sans-serif;
$font-family-condensed: "Open Sans Condensed", sans-serif;
$font-size-base: 16px;
$line-height-base: 1.6;
$text-color: #46555d;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Bootstrap-sass glyphicon fix
$icon-font-path: '../fonts/bootstrap/';
