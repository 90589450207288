.admin {
  min-height: 77vh;
  .btn-logout{
    font-size: 12px;
    //margin-bottom: 20px;
    float: right;
    padding: 2px 7px;
    margin: 5px 5px 10px 5px;
  }

  .sidebar{
    clear: both;

    .menu > li{
      margin-bottom: 10px;

      .btn{
        font-size: 14px;
        white-space: inherit;
        transition: all 0.5s;
      }
    }
  }

  &-header{
    h1{
      margin-top: 0;
    }

    &-buttons{
      margin-bottom: 20px;
      text-align: right;

      .btn{
        font-size: 13px;
        margin-bottom: 10px;
        height: 34px;
        transition: all 0.5s;
      }
    }
  }

  &-filter{
    margin-bottom: 15px;
    max-width: 800px;
    font-size: 14px;

    &-dropdown{
      .form-control{
        height: 32px;
        font-size: 14px;
        padding: 2px;
      }
    }

    &-date{
      label{
        padding-right: 10px;
      }
    }
  }

  &-table{

    &.table-responsive{
      border: none;
    }

    .table{
      border: 1px solid #ccc;
      font-size: 13px;

      thead > tr{
        background: $text-color;
        color: $white;
      }

      tfoot {
        display: table-header-group;

        th{
          &:first-child, &:last-child{
            input[type="text"]{ display: none; }
          }
          input[type="text"]{
            font-weight: normal;
            min-width: 94px;
            width: 75%;
          }
          select{
            font-weight: normal;
          }
        }
      }

      th{
        font-size: 11px;
        text-transform: uppercase;
        vertical-align: middle;
        text-align: center;
        padding: 8px 15px 8px 10px;
      }

      tbody{
        > tr{
          &:nth-of-type(odd){
            background: $white;
          }
          &:nth-of-type(even){
            background: #f9f9f9;
          }

          td{
            &:first-child{
              text-align: center;
            }
          }
        }
      }
    }
  }

  #users, #pickupDates{
    tbody{
      > tr{
        td{
          text-align: center;
        }
      }
    }
  }

  #changelog{
    thead{
      .sorting{
        &:after{
          right: 10px;
        }
      }
    }
  }

  &-form{
    h3{
      margin-top: 0;
    }

    &-section{
      padding-bottom: 20px;

      label, input{
        font-size: 14px;
      }

      .content-form-bike-info{
        margin-bottom: 0;
      }
    }
  }

  &-settings{
    background: $white;
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 30px 30px 20px 30px;
    max-width: 800px;

    .btn-success{
      float: right;
    }

    h3{
      margin-top: 5px;
    }

    &-dates{
      margin-top: 20px;

      /*> .row{
        padding-bottom: 20px;
      }*/

      &-col{
        display: inline-block;
        margin-bottom: 30px;
        width: 48%;
        padding: 0 2% 0 0;
      }
    }

    label{
      font-size: 14px;
      padding-right: 10px;
    }
  }
}

.container{
  &.admin{
    @media (min-width: 1400px){
      width: 100%;
    }
  }
}

input.initial-width {
  width: initial;
}

.datepicker, .admin-filter-date input{
  background: $white url('/images/calendar.png') no-repeat 95% center;
  background-size: 15%;

  &.form-control{
    background: $white url('/images/calendar.png') no-repeat 95% center;
    background-size: 10%;
    width: initial;
    &[disabled] {
      background: #eee;
      opacity: 1;
    }
  }
}

.admin-filter-date input{
  height: auto;
  width: auto;
  padding: 2px 6px;
}

ul.checkmarks {
  padding-left: 20px;
  text-indent: 2px;
  list-style: none;
  list-style-position: outside;

  li:before {
    content: '✔';
    margin-left: -1em;
    margin-right: .100em;
  }
}

ul.api-endpoints {
  list-style-type: none;

  li {
    margin: 5px 0 0 -38px;

    span.test-indicator {
      width: 45px;
      color: #edeeef;
      padding: 0 5px;
      float: left;
      font-size: 87%;
      margin: 0 10px 0 0;
      border-radius: 2px;
      text-align: center;
    }
    span.description {
      color: $brand-info;
      font-size: smaller
    }
  }
}

.admin-header-buttons {
  .btn-group {
    .dropdown-menu {
      margin-left: -72px;
      margin-top: -8px;
      font-size: 80%;
      li {
        a {
          &:hover {
            background: #e6e6e6;
          }
          &.unregistered-homes-excel {
            font-size: 110%;
            cursor: hand;
            cursor: pointer;
          }
          input[type=checkbox] {
            margin-top: 0;
            vertical-align: middle;
          }
        }
      }
    }
    fieldset.bulk-operations {
      border: 1px dashed #46555d;
      padding: 10px;

      label {
        margin-top: -24px;
        position: absolute;
        background: #edeeef;
        left: 10px;
        padding: 0 6px;
      }
    }
  }
  a, button {
    margin-left: 15px;
  }
}

.upcoming-pickup {
  font-size: 14px;
  a {
    &:hover {
      text-decoration: none;
      color: #46555d;
    }
    color: #46555d;
    span {
      font-weight: bold;
      color: $brand-primary;
    }
  }
}

.homesite-indicator{
  &.fa-check{
    color: $brand-success;
  }
  &.fa-times{
    color: $brand-danger;
  }
}

.table > tbody > tr > td {
  border-left: 1px dashed #ddd;
  &.success {
    background: #e4ffe4;
  }
  &.forfeit {
    background: #ffdbdb;
  }
}

a.action {
  border-radius: 3px;
  padding: 5px 10px;
  margin: 5px;
  color: white;
  font-weight: normal;
  &:hover, &:active, &:focus {
    color: white;
    transition: all 0.5s;
    text-decoration: none;
  }
}

a.btn-link {
  background: #f4751c;
  &:hover, &:active, &:focus {
    background: #ba5209;
  }
}
a.btn-deactivate {
  background: #bf5329;
  &:hover, &:active, &:focus {
    background: #954120;
  }
}
a.btn-reactivate {
  background: #8eb4cb;
  &:hover, &:active, &:focus {
    background: #6b9dbb;
  }
}

button.residents-excel:after {
  margin-right: 15px;
}

div.filters {
  margin-bottom: 15px;
}

label[for="neighborhood"] {
  display: block;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

a.notifications{
  color: $text-color;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
  div.notifications-count{
    display: inline-block;
    text-align:center;
    cursor: pointer;
    overflow: hidden;
    position: absolute;
    min-width: 25px;
    line-height: 15px;
    padding: 3px;
    border: 1px solid $brand-secondary;
    top: -15px;
    left: 30px;
    z-index: 2;
    color: $brand-secondary;
    background: $notifications;
    border-radius: 5px;
  }
  i{
    font-size: 24px;
    cursor: pointer;
  }
  i:hover{
    transition: color 0.5s ease-in-out;
    color: $brand-primary;
  }
}

.bt{
  border-top: 1px solid #d2d5d7;
}

.mt-10{
  margin-top: 10px;
}

.pt-10{
  padding-top: 10px;
}

.bg-secondary {
  background-color: #46555d;
}
.bg-success {
  background-color: $brand-success;
}
.bg-failure {
  background-color: $brand-danger;
}
.bg-warning {
  background-color: $brand-warning;
}

#notesGroup {
  position: relative;
  #counter {
    position: absolute;
    right: 0;
    top: 0;
  }
}

#runApiTests {
  margin-top: 25px;
}
