.footer{
  background: $white;

  &-logo{
    padding-bottom: 30px;

    img{
      max-width: 220px;
    }
  }
}