.header{
  padding: 20px 0;
}

.dashboard-header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div{
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
}

.dashboard-header{
  @media (min-width: 1200px) {
    width: 100%;
  }
}