.pop-up{
  display: none;

  &-content{
    background: $white;
    border-radius: 3px;

    &-header{
      background: $text-color;
      text-align: center;
      border-radius: 2px 2px 0 0;
      padding: 15px;

      h2{
        margin: 0;
        color: $white;
        text-transform: uppercase;
        font-family: $font-family-condensed;
        font-weight: bold;
        font-size: 28px;
      }
    }

    &-options{
      padding: 20px;

      &-list{
        background: $white;
        margin: 10px 0 20px 0;
        border-radius: 3px;
        box-shadow: 0 1px 1px rgba($black, 0.2);
      }

      &-list .form-group{
        padding: 13px 20px;
        margin: 0;
        //text-align: center;

        &:not(:last-child){
          border-bottom: 1px solid darken($brand-secondary, 8%);
        }

        .radio-inline{
          padding-left: 30px;

          input[type="radio"]{
            margin-left: -30px;
          }
        }
      }
    }
  }
}

.pop-up-location{
  font-size: 14px;
  text-align: center;
}

.popup-content-terms{
  margin-bottom: 20px;

  label{
    font-size: 14px;
    font-weight: bold;
  }

  .form-control{
    max-width: 90px;
  }
}