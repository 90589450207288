.required{
  color: red;
  font-weight: normal;
}

.form-inline-text{
  padding-top: 7px;
}

.form-error{
  display: none;
  font-size: 14px;
}

.form-horizontal{
  .form-error{
    margin: -20px 0 10px 0;
  }
}

.content-form{
  &-thumb{
    margin-bottom: 20px;

    img{
      max-width: 100%;
    }
  }

  &-extra{
    margin-top: 20px;
  }

  &-address{
    select{
      margin-bottom: 20px;
    }

    .form-address-same{
      text-align: right;
    }
  }

  .form-contact-pickup{
    padding-top: 15px;
  }
}

.form-contact-pickup{
    padding-top: 15px;
}

.form-btn-row{
  @media only screen and (min-width : 992px) {
    margin-top: 40px;
  }

  .btn-prev{
    margin-bottom: 20px;
  }
}

.form-contact-opts{
  display: none;
}

.form-contact-pickup {
  padding-right: 25px;
}

.file-upload {
  position: relative;
  overflow: hidden;

  &.btn{
    font-size: 14px;
    margin-bottom: 10px;
  }

  .upload{
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 14px;
    cursor: pointer;
    opacity: 0;
  }

  + a{
    font-size: 14px;
  }
}

.docusign-links {
  @media only screen and (min-width : 992px) {
    float: right;
  }
  a {
    display: block;
    font-size: 14px;
  }
}

.bike-info-options{
  margin-bottom: 10px;
}

.no-pickup-date-disclaimer {
  font-size: 12px;
  color: red;
}