.content-header{
  background: $text-color url('/images/header.png') no-repeat center;
  background-size: cover;
  color: $brand-primary;

  @media only screen and (min-width : 992px) {
    padding: 30px 0 20px 0;
  }

  h1{
    color: $white;
    margin-top: 0;
    padding: 30px;
  }
}

.content{
  background: $light-gray;
  color: $text-color;

  h1, h2, h3{
    font-family: $font-family-condensed;
    font-weight: bold;
    text-transform: uppercase;
  }

  h1{
    font-size: 30px;
    padding-bottom: 30px;
  }

  h2{
    font-size: 27px;
    margin-top: 0;
    margin-bottom: 30px;
  }

  h3{
    font-size: 24px;
  }

  > .container{
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &-step{
    padding: 20px 0;

    &-final{
      max-width: 600px;
      margin: 20px auto;
      background: $white;
      padding: 20px 30px;
      border-radius: 3px;
      box-shadow: 0 1px 1px rgba($black, 0.2);

      .row {
        &:not(:last-child){
          border-bottom: 1px solid $brand-secondary;
          padding-bottom: 10px;
          margin-bottom: 20px;
        }
      }
    }

    &-2-pickup{
      clear: both;
    }
  }

  &-form{
    @media only screen and (min-width : 992px) {
      &.has-divider{
        border-left: 1px solid darken($light-gray, 10%);
        padding-left: 30px;
      }
      &.right-divider{
        border-right: 1px solid darken($light-gray, 10%);
        padding-right: 30px;
      }
    }

    &-step{
      margin: 0 0 25px 0;

      &-num{
        display: inline-block;
        background: $brand-primary;
        border: 1px solid darken($brand-primary, 15%);
        color: $white;
        font-size: 18px;
        vertical-align: middle;
        text-align: center;
        border-radius: 100%;
        width: 32px;
        height: 32px;
        line-height: 29px;
        margin-right: 15px;
        float: left;
      }

      + .form-group{
        clear: both;
      }
    }

    &-status, &-bike-info{
      padding-left: 0;
      margin: 10px 0;
    }
  }

  .select-bike{
    min-height: 150px;
    height: auto;
  }
}

.btn{
  &-step{
    background: $white;
    display: inline-block;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 22px;
    border-radius: 100%;
    color: $brand-primary;
    margin-right: 15px;
    font-weight: bold;
  }

  &-default{
    .btn-step{
      background: $text-color;
      color: $white;
    }
  }
}

.breadcrumb{
  font-size: 12px;
}

.red { color: #ff4a54 !important; }
.orange { color: #f4751c !important; }
.select-bike { height: 150px; }
.default-link { text-decoration: underline; }
.red.default-link:hover { color: #c84e55 !important; }

.tooltip-toggle {
  position: relative;
  display: inline-block;
}

.tooltip-toggle + .tooltip-text {
  visibility: hidden;
  position: absolute;
  width: 440px;
  text-align: center;
  z-index: 99;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(47, 47, 47, 0.9);
  color: #fff;
}

.tooltip-text:after {
  position: absolute;
  bottom: 100%;
  left: 5%;
  margin-left: -5px;
  width: 0;
  border-bottom: 5px solid rgba(47, 47, 47, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

.tooltip-toggle:hover + .tooltip-text {
  visibility: visible;
}